<template>
  <div>
    <div class="header">
      <div>
        <el-page-header @back="goBack" content="返佣列表"> </el-page-header>
      </div>
      <el-button type="primary" @click="addReturn()" size="small"
        >新建返佣计划
      </el-button>
    </div>
    <ListFilter :total="total" @close="putAway">
      关键字：
      <el-input
        placeholder="请输入关键字"
        v-model="formInline.keywords"
        @input="loadData()"
        size="mini"
        style="margin-right: 10px; width: 300px"
        prefix-icon="el-icon-search"
        clearable
      >
      </el-input>
    </ListFilter>
    <el-table :data="list" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column label="返佣名称">
        <template slot-scope="scope">
          <span
            class="clickColor"
            v-prev
            :path="'/system/recruit/returnDetail'"
            :query="{ name: scope.row.name }"
            >{{ scope.row.name }}</span
          >
        </template>
      </el-table-column>

      <el-table-column label="返佣备注">
        <template slot-scope="scope">
          <span>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <el-switch
            style="display:block"
            v-model="scope.row.delete"
            active-color="#409eff"
            active-text="启用"
            inactive-text="停用"
          >
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        layout="prev,pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <AddReturn
      :dialogVisible.sync="addDialogVisible"
      @close="addClose()"
    ></AddReturn>
  </div>
</template>

<script>
import { api } from '/src/api/base';
export const RecruitReturnPlanList = api('/recruitReturnPlan')(
  'recruitReturnPlan.list.json'
);

export default {
  components: {
    ListFilter: () => import('/src/components/filter'),
    AddReturn: () => import('./addReturn')
  },
  data() {
    return {
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: ''
      },
      list: [],
      addDialogVisible: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    addReturn() {
      this.addDialogVisible = true;
    },
    addClose() {
      // this.loadData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData() {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline
      };
      RecruitReturnPlanList(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    putAway() {
      this.pageNumber = 1;
      this.formInline.keywords = '';
      this.loadData();
    },
    goBack() {
      window.history.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
